<template>
    <div class="main-wrap">
        <router-view></router-view>
    </div>
</template>

<script>

export default ({
    name: 'Blank'
})
</script>
